import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Translator } from './components/Translator';

import './custom.css';

export default class App extends Component {

    render () {
        return (
            <Translator>
                <Layout />
            </Translator>
        );
    }
}
