import React, { useState, useEffect } from 'react';
import * as State from '../State';
import { useTranslate } from 'react-translate';
import { getCSRFToken } from '../../../utils/CSRFHelper';

import './VoteActivity.css';


const inDuration = 500;
const delayBetweenVotes = 10;
const outDuration = 10;
let initialState = true

export function VoteActivity(props) {
    const common = useTranslate('Common');
    const [state, setState] = useState(State.init);
    const [vote, setVote] = useState('');
    let [items, setItems] = useState([])
    const { callHubAsync, subscribeToEvent } = props;

    useEffect(() => {
        let cancelled = false;
        let onError = (error) => {
            initialState = true
            if (!cancelled) {
                console.error(error);
                setState(State.error);
            }
        }
        if (state === State.init) {
            initialState = true
            new Promise(r => setTimeout(r, inDuration))
                .then(() => {
                    if (!cancelled) {
                        setState(State.ready);
                    }
                })
                .catch(onError);

            return () => cancelled = true;
        }
        if (state === State.ready) {
            
            async function displayImages(choicesArray) {
                items = await displayImagesVote(choicesArray, false)
                setItems(items)
            }

            if (initialState) {
                displayImages(props.elements);
                initialState = false
            }
            
            if (subscribeToEvent) {
                var eventName1 = "voteChoicesUpdated";

                var unsubscriber = subscribeToEvent(eventName1, (args) => {
                    async function displayImages(choicesArray) {
                        items = await displayImagesVote(choicesArray, true)
                        setItems(items)
                    }
                    displayImages(args.choices);
                })

                var eventName2 = "resetVotes";
                var unsubscriber2 = subscribeToEvent(eventName2, (args) => {
                    let boxes = document.querySelectorAll('.clicked');
                    boxes.forEach(box => {
                        box.classList.remove('clicked');
                    });
                })
            }
            
           
        }
        if (state === State.sending) {
            async function sendAsync() {
                try {
                    const result = await callHubAsync("SendVote", vote);
                    if (result) {
                        await new Promise(r => setTimeout(r, outDuration + delayBetweenVotes));

                        if (cancelled) return;

                        setVote(undefined);
                        setState(State.ready);
                    }
                    else {
                        onError("failed to send vote");
                    }
                }
                catch (error) {
                    onError(error);
                }
            }

            sendAsync();

            return () => cancelled = true;

        } else {
            return undefined;
        }
    }, [state, callHubAsync, setVote, setState, setItems, subscribeToEvent])

    async function displayImagesVote(choicesArray, fromEvent) {
        
        let itemList = []
        
        for (const [index, value] of choicesArray.entries()) {
            //requete pour get le fichier ?
            let file = null
            if (fromEvent) {
                file = await getFileAsync(value.fileId);
            } else {
                file = await getFileAsync(value.value);
            }
            if (file != null) {
                var binaryData = [];
                if (value.key === undefined) {
                    binaryData.push(file);
                    let urlImage = window.URL.createObjectURL(new Blob(binaryData, { type: "image/png" }))
                    itemList.push(
                        <div key={value.id} className="voteContainer" onClick={() => displayNumber(value.id + "_checkButton")}>
                            <img className="image" id={value.id} alt="PossibleChoice" src={urlImage}></img>
                            <img className="check" id={value.id + "_checkButton"} alt="PossibleChoice" src="./assets/check.png"></img>
                        </div>
                    )
                } else {
                    binaryData.push(file);
                    let urlImage = window.URL.createObjectURL(new Blob(binaryData, { type: "image/png" }))
                    itemList.push(
                        <div key={value.key} className="voteContainer" onClick={() => displayNumber(value.key + "_checkButton")}>
                            <img className="image" id={value.key} alt="PossibleChoice" src={urlImage}></img>
                            <img className="check" id={value.key + "_checkButton"} alt="PossibleChoice"  src="./assets/check.png"></img>
                        </div>
                    )
                }
                
            }
            
        }
        setItems(itemList)
        
        return itemList;
    }

    async function getFileAsync(fileId) {
        return await fetch('api/upload/'+ fileId, {
            method: 'GET',
            body: null,
            headers: {
                SessionId: props.sessionId,
                ConnectionId: props.connectionId,
                UserId: props.userId,
                RequestVerificationToken: getCSRFToken()
            }
        }).then((response) => response.blob())
            .then(data => {
            return data;
        })
    }

    function displayNumber(id) {
        //rajouter requete http + lien avec le services
        if (document.getElementsByClassName("clicked").length === 1 && document.getElementsByClassName("clicked")[0].id === id) {
            let boxes = document.querySelectorAll('.clicked');
            boxes.forEach(box => {
                box.classList.remove('clicked');
            });
        } else {
            let boxes = document.querySelectorAll('.clicked');
            boxes.forEach(box => {
                box.classList.remove('clicked');
            });
            document.getElementById(id).classList.add("clicked")
        }
        let selectedChoices = (Array.from(document.querySelectorAll('.clicked'))[0]?.id)?.replace("_checkButton", '')
        setVote(selectedChoices)
        sendMessage(selectedChoices)
    }

    function sendMessage(event) {
        setVote(event)
        setState(State.sending);
    }

    return (
        <form>
            <div className="wrapper">
                {items}
            </div>

            <p style={{ opacity: state === State.error ? 1 : 0 }}> {/* just hidden to keep layout */}
                {common('error.retry')}
            </p>
        </form>
    );
}

    