import React, { useEffect } from "react";
import autosize from "autosize";

import './NoteActivity.css';

export function AutoResizeTextArea(props) {
    useEffect(() => {
        autosize(props.innerAreaRef.current);
        return () => {
            if (props.innerAreaRef.current)
                autosize.destroy(props.innerAreaRef.current);
        }
    });

    const estimatedLineSize = 19;
    const maxLines = 4;

    function handleChange(event) {
        let newValue = event.target.value;

        // Recreate (approximatively) the lines displayed on screen
        // to detect if we reached the lines limit 
        const unflatLines = newValue
            .replace('\r', '')
            .split('\n')
            .map((line) => {
                if (!line) return [''];

                const innerLines = [];
                let rest = line;
                while (rest && rest.length > 0) {
                    innerLines.push(rest.slice(0, estimatedLineSize));
                    rest = rest.slice(estimatedLineSize);
                }
                return innerLines;
            })
            // .flat(); >> not suppoted by old edge !

        const lines = [];
        unflatLines.forEach(array => array.forEach(e => lines.push(e)));

        const lengthDif = newValue.length - props.text.length;
        if (lengthDif > 0 && lines.length > maxLines) {
            // constraint value if too much lines, but still allow char suppression

            if (lengthDif > 2) {
                // if number of new chars is important (ie. user pasted something)
                // limit char & lines number;

                newValue = newValue
                    .slice(0, maxLines * estimatedLineSize)
                    .split('\n')
                    .slice(0, maxLines)
                    .join('\n');
            } else {
                // prevent new char addition : restore old value
                newValue = props.text;
            }
        }

        props.setText(newValue);
    }

    return (
        <textarea
            className="note"
            ref={props.innerAreaRef}
            rows={1}
            onChange={handleChange}
            value={props.text}
            aria-label="Note Text"
            readOnly={props.readOnly}
        />
    );
}