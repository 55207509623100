import React, { useState, useEffect } from 'react';
import { DocumentPresenter } from './DocumentPresenter';
import { useTranslate } from "react-translate";
import { useSetTimeout } from "../../../utils/useSetTimeout"
import { AbortError } from '@microsoft/signalr';

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}


export function DocumentDownloader(props) {
    const {
        pageCount,
        startPage,
        subscribeToEvent,
        callHubAsync,
        paletteColors,
        setTitleClassName,
        setError
    } = props;
    const [pages, setPages] = useState(Array(pageCount));
    const [isTimerRunning, setIsTimerRunning] = useSetTimeout(30000);
    const abortController = new AbortController();
    const t = useTranslate("Presenter");

    function onReady() {
        setPages(prevPages => {
            var clone = prevPages.map(p => p);
            return clone;
        }); // update props
        //setIsTimerRunning(false);
    }

    async function initPageAsync(page) {
        try {
            var result = await fetch(page.sourceUrl, { signal: abortController.signal });
            if (result && result.ok) {
                var blob = await result.blob();

                const reader = new FileReader();
                reader.onloadend = () => {
                    var base64 = arrayBufferToBase64(reader.result);
                    page.source = "data:"+ blob.type + ";base64, " + base64;
                    page.ready = true;
                    onReady();
                };
                reader.onerror = () => {
                    setError(t("error.pageLoading"));
                };
                reader.onabort = () => {
                    setError(t("error.pageLoading"));
                };
                reader.readAsArrayBuffer(blob);
            } else {
                setError(t("error.pageLoading"));
            }
        } catch (ex) {
            if (ex.name === "AbortError") {
                return;
            }
            setError(t("error.pageLoading"));
        }
        
    }

    async function alertPageChangedAsync(index) {
        var needsUpload = pages[index] === undefined || !pages[index].ready;
        if (needsUpload) {
            setIsTimerRunning(true);
        }
        await callHubAsync(
            "changePage",
            index,
            needsUpload
        );
    }

    useEffect(() => {
        if (subscribeToEvent) {
            var eventName = "pageUploadStarted";

            var unsubscriber = subscribeToEvent(eventName, args => {
                const { id, index, size } = args;

                var newPage = {
                    id,
                    ready: false,
                    sourceUrl: `api/Files/${id}`,
                    annotations: []
                };

                initPageAsync(newPage);

                setPages(prevPages => {
                    var clone = prevPages.map(p => p);
                    clone[index] = newPage;
                    return clone;
                });

            });

            return unsubscriber;
        }

        return undefined;
    }, [subscribeToEvent, pages]);

    useEffect(() => {
        return () => {
            setIsTimerRunning(false);
            abortController.abort();
        }
    }, []);


    return <DocumentPresenter
        pages={pages}
        callHubAsync={callHubAsync}
        alertPageChangedAsync={alertPageChangedAsync}
        paletteColors={paletteColors}
        setTitleClassName={setTitleClassName}
        initialPageIndex={startPage}
        isTimerRunning={isTimerRunning}
        setError={setError}
    />
}
