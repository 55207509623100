import React from 'react';
import { useTranslate } from 'react-translate';
import { Spinner } from 'reactstrap';

import * as State from './State';
import { ThemedButton } from '../ThemedButton';

export function SendButton(props) {
    const common = useTranslate('Common');
    
    return (<ThemedButton
        type="submit"
        content={props.state === State.sending ?
            (<>
                <Spinner color="light" size="sm" />
                {props.progress !== undefined && <span className="progressCount">{props.progress} %</span>}
            </>)
            : (<>
                <span>{props.state === State.error ? common('retry') : common('send')}</span>
                <svg
                    className="ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width={24}
                    height={24}
                    style={{
                        fill: '#ffffff',
                        stroke: 'none'
                    }}
                    viewBox="0 0 32 32">
                    <path d="M 0 3.2949219 L 3.9980469 16 L 0 28.705078 L 32.041016 16 L 0 3.2949219 z M 3.1640625 6.7050781 L 24.089844 15 L 5.78125 15 L 3.1640625 6.7050781 z M 5.78125 17 L 24.089844 17 L 3.1640625 25.294922 L 5.78125 17 z" />
                </svg >
            </>)
        }
        onClick={props.onClick}
        disabled={props.disabled || props.state === State.init || props.state === State.sending}
    />);
}