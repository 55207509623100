import React, { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ActivityManager } from './ActivityManager';
import { Form, Input } from 'reactstrap';
import { ThemedButton } from './ThemedButton';
import { useTranslate } from 'react-translate';
import { Logo } from './Logo';

import { version } from "../../package.json";

export function Home(props) {
    const t = useTranslate('Home');
    const { sessionId } = useParams();
    const history = useHistory();
    const [customSessionId, setCustomSessionId] = useState('');
    const { footerRef } = props;

    function submit(event) {
        event.preventDefault();

        if (customSessionId) {
            history.push("/" + customSessionId);
        }
    }

    function home() {
        history.push("/");
    }

    const retrieveUserConnectInfo = useCallback(() => history.location.state, []);
    const storeUserConnectInfo = useCallback((userName, userId) => history.replace(history.location.pathname, { userName, userId }), []);
     if (sessionId)
        return <ActivityManager sessionId={sessionId.toLowerCase()} {... { retrieveUserConnectInfo, storeUserConnectInfo, home, footerRef }} />;
    else {
        return (
            <>
                <Logo maxSize={150} />
                <h2>{t('title')}</h2>
                <p className="hide-when-super-small">{t('instructions.fullUrl')}<br />{t('instructions.type')}</p>
                <Form className="d-inline-flex" style={{ marginBottom: 60 /* compensate the bottom logo */ }}>
                    <Input
                        className="mr-2"
                        type="text"
                        value={customSessionId}
                        onChange={e => setCustomSessionId(e.target.value)}
                        aria-label={t('sessionId')}
                        placeholder={t('sessionId')}
                    />
                    <ThemedButton type="submit" onClick={submit} content="Ok" disabled={!customSessionId} />
                </Form>
                <p className="versionNumber">v{version}</p>
            </>
        );
    }
}