import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as signalR from "@microsoft/signalr"

import { ThemeContext, defaultTheme } from '../Theme';
import { LoginForm } from './LoginForm';
import { Spinner } from 'reactstrap';
import { ThemedButton } from './ThemedButton';
import { useTranslate } from 'react-translate';
import { ActivityRenderer } from './ActivityRenderer';
import { Logo } from './Logo';

import * as State from './ConnectionState';
import * as SessionState from './SessionState';
import '../custom.css'

const hexaChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

// returns a number between 0 and 1 depending on input string
function getSeed(input) {
    return Math.max(0, hexaChars.indexOf(input.charAt(0)) / hexaChars.length);
}

export function ActivityManager(props) {
    const common = useTranslate('Common');
    const t = useTranslate('ActivityManager');
    const [reconnect, setReconnect] = useState(false);
    const [userId, setUserId] = useState('');
    const [projectName, setProjectName] = useState('');
    const [activity, setActivity] = useState('');
    const [activityArgs, setActivityArgs] = useState(undefined);
    const [sessionExistance, setSessionExistance] = useState(undefined);
    const [sessionState, setSessionState] = useState(SessionState.CheckingSession);
    const [connectionState, setConnectionState] = useState(State.Loading);
    const [theme, setTheme] = useState(defaultTheme);
    const [elements, setElements] = useState('');
    const [voteChoice, setVoteChoice] = useState('');

    function setActivitySerializedArgs(serializedArgs) {
        setActivityArgs(!!serializedArgs ? JSON.parse(serializedArgs) : undefined);
    }

    function clearFooterClassName() {
        if (footerRef && footerRef.current) {
            footerRef.current.classList = "";
        }
    }

    function addFooterClassName(name) {
        if (footerRef && footerRef.current) {
            footerRef.current.classList.add(name);
        }
    }

    function setFooterClassName(name) {
        clearFooterClassName();
        addFooterClassName(name);
    }

    const connectionRef = useRef();

    const {
        sessionId,
        retrieveUserConnectInfo,
        storeUserConnectInfo,
        home,
        footerRef
    } = props;

    // initialize SignalR connection
    useEffect(() => {
        async function signalRConnect() {
            try {
                setConnectionState(State.Loading);

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl('/companionHub')
                    .withAutomaticReconnect()
                    .build();

                connection.on('startingActivity', infos => {
                    setActivitySerializedArgs(infos.serializedArgs);
                    setActivity(infos.activity);
                });
                connection.on('pauseSession', () => {
                    setActivity(undefined);
                    setSessionState(SessionState.SessionPaused);
                    setFooterClassName("logoContainer");
                });
                connection.on('restartSession', () => {
                    setActivity(undefined);
                    setSessionState(SessionState.LoggedIn);
                    setFooterClassName("logoContainer");
                });
                connection.on('stoppingActivity', () => {
                    setActivity(undefined);
                    setFooterClassName("logoContainer");
                });
                connection.on('stoppingSession', () => {
                    setSessionState(SessionState.SessionEnded);
                    setFooterClassName("logoContainer");
                });
                connection.onreconnecting(() => {
                    setConnectionState(State.Reconnecting);
                });
                connection.onreconnected(() => {
                    setConnectionState(State.Connected);
                });
                connection.onclose(() => {
                    setConnectionState(State.Disconnected);
                });

                connectionRef.current = connection;
                await connectionRef.current.start();
                setConnectionState(State.Connected);
            } catch (err) {
                setConnectionState(State.Error);
                console.error(err.toString());
            }
        }

        signalRConnect();

        return () => {
                const connection = connectionRef?.current;
                if (connection) {
                    connection.off('startingActivity');
                    connection.off('stoppingActivity');
                    connection.off('pauseSession');
                    connection.off('restartSession');
                    connection.off('stoppingSession');
                    connection.stop()
                        .then(() => setConnectionState(State.Disconnected))
                        .catch(err => {
                            setConnectionState(State.Error);
                            console.error(err.toString());
                        });
                }
            };
        },
        [reconnect]//, clearUserConnectInfo] // the function will be called at first render and each time the value of 'reconnect' is changed (no matter true or false !)
    );

    // check session existence && download its theme && background
    useEffect(() => {
        async function getSessionInfoAsync() {
            const result = await fetch(`api/session/${sessionId}/info`);
            const info = result && result.ok && await result.json();
            if (info) {
                setProjectName(info.name);
                setTheme(info.theme);
                setSessionExistance(true);

                document.body.style.background = `radial-gradient(circle at 80% 80%, ${info.theme.darkColor}, ${info.theme.darkDarkColor})`;

                const backgroundElement = document.getElementById('customBackground');
                backgroundElement.style.backgroundImage = `url(api/session/${sessionId}/background)`;
                backgroundElement.style.opacity = 1;
            } else {
                setSessionExistance(false);
            }
        }

        getSessionInfoAsync();

    }, [sessionId]);


    async function genericCallHubAsync(...args) {
        return await connectionRef.current.invoke(...args);
    }

    async function publicCallHubAsync(methodName, ...args) {
        return await genericCallHubAsync(methodName, sessionId, userId, ...args);
    }

    function subscribeToEvent(eventName, callBack) {
        connectionRef.current.on(eventName, callBack);
        return () => connectionRef.current.off(eventName);
    }


    const joinSessionAsync = useCallback(async (userName, previousId = undefined) => {
        try {
            const sessionConnectionInfo = previousId ?
                await genericCallHubAsync('RejoinSession', sessionId, userName, previousId)
                : await genericCallHubAsync('JoinSession', sessionId, userName);
            
            if (sessionConnectionInfo) {
                setUserId(sessionConnectionInfo.userId);
                setActivitySerializedArgs(sessionConnectionInfo.serializedArgs);
                setActivity(sessionConnectionInfo.activity);
                if (sessionConnectionInfo.isPaused) {
                    setSessionState(SessionState.SessionPaused);
                }
                else {
                    setSessionState(SessionState.LoggedIn);
                }

                // store userName to reuse it in case of page refresh
                storeUserConnectInfo(userName, sessionConnectionInfo.userId);
            } else {
                setSessionState(SessionState.LoginFailed);
            }
        } catch (err) {
            console.error(err.toString());
            setSessionState(SessionState.LoginFailed);
        }
    }, [storeUserConnectInfo, sessionId]);

    // auto reconnect with previous name in case of page refresh.
    // Needs that the two above USEEFFECTS are ended to apply correct sessionState (or join session if possible)
    useEffect(() => {
        try {
            if (connectionState === State.Connected && sessionExistance !== undefined) {

                if (!sessionExistance) {
                    setSessionState(SessionState.UnkownSession);
                } else {
                    const info = retrieveUserConnectInfo();
                    if (info?.userName && info?.userId) {
                        console.log(`reconnect user (${info.userId}:${info.userName})`);
                        joinSessionAsync(info.userName, info.userId);
                    } else {
                        setSessionState(SessionState.NotLogged);
                    }
                }
            }
        } catch (err) {
            console.error(err.toString());
        }        
    }, [connectionState, sessionExistance, retrieveUserConnectInfo, joinSessionAsync]);


    function ReconnectButton() {
        return <ThemedButton content={common('retry')} onClick={e => setReconnect(v => !v)} />;
    }

    function HomeButton() {

        return <ThemedButton content={t('home')} onClick={e => home()} />;
    }

    function RenderContent() {
        
        if (connectionState === State.Error) {
            return <>
                <p>{common('error')}</p>
                <ReconnectButton />
            </>;
        } else if (connectionState === State.Loading) {
            return <Spinner color="light" className='spinner'/>;
        } else if (connectionState === State.Connected) {
            switch (sessionState) {
                case SessionState.CheckingSession:
                    return <Spinner color="light" className='spinner' />;
                case SessionState.UnkownSession:
                    return <>
                        <p>{t('session.unknown')}</p>
                        <HomeButton />
                    </>;
                case SessionState.LoggedIn:
                    return (<ActivityRenderer {... {
                        activity,
                        projectName,
                        sessionId: props.sessionId,
                        connectionId: connectionRef.current.connectionId,
                        activityArgs,
                        colorSeed: getSeed(userId),
                        callHubAsync: publicCallHubAsync,
                        userId,
                        subscribeToEvent,
                        setFooterClassName,
                        addFooterClassName,
                        elements
                    }}
                    />);
                case SessionState.SessionEnded:
                    return <>
                        <p>{t('session.ended')}</p>
                        <HomeButton />
                    </>;
                case SessionState.SessionPaused:
                    return <>
                        <p>{t('session.paused')}</p>
                    </>;
                case SessionState.NotLogged:
                case SessionState.LoginFailed:
                default:
                    return (<>
                        <Logo maxSize={150} />
                        <h2>{t('session.joining')} {projectName}</h2>
                        <LoginForm {...{ joinSessionAsync }} />
                        {sessionState === SessionState.LoginFailed &&
                            <p>{common('error.retry')}</p>
                        }
                    </>);
            }
        } else if (connectionState === State.Reconnecting) {
            return <>
                <Spinner color="light" className='spinner'/>
                <p>{t('connection.reconnecting')}</p>
            </>;
        } else if (connectionState === State.Disconnected) {
            return <>
                <p>{t('connection.disconnected')}</p>
                <ReconnectButton />
            </>;
        }
    }

    return (<ThemeContext.Provider value={theme}>
            <RenderContent />
        </ThemeContext.Provider>);
}