import React, { useEffect, useRef} from 'react';
import { useTranslate } from 'react-translate';
import * as Activities from './Activities';

export function ActivityRenderer(props) {
    const t = useTranslate('ActivityManager');
    const { activity, projectName, setFooterClassName, addFooterClassName, ...innerProps } = props;
    const titleRef = useRef(null);

    useEffect(() => {
        document.title = "Compositeur Digital UX - " + projectName;
    }, [projectName]);

    function Activity() {
        switch (activity) {
            case Activities.Note:
                return <Activities.NoteActivity 
                    colorSeed={innerProps.colorSeed} 
                    callHubAsync={innerProps.callHubAsync}
                />;
            case Activities.UploadFile:
                return <Activities.UploadFileActivity
                    activityArgs={innerProps.activityArgs}
                    sessionId={innerProps.sessionId}
                    userId={innerProps.userId}
                />;
            case Activities.Presenter:
                return <Activities.PresenterActivity
                    activityArgs={innerProps.activityArgs}
                    sessionId={innerProps.sessionId}
                    userId={innerProps.userId}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    callHubAsync={innerProps.callHubAsync}
                    titleRef={titleRef}
                    setFooterClassName={setFooterClassName}
                    addFooterClassName={addFooterClassName}
                />;
            case Activities.Vote:
                let elementsArray = []
                for (let i = 0; i < props.activityArgs.choices.length; i++) {
                    elementsArray.push({
                        'key': props.activityArgs.choices[i].id,
                        'value': props.activityArgs.choices[i].fileId
                    })
                }
                return <Activities.VoteActivity userId={innerProps.userId}
                    sessionId={innerProps.sessionId}
                    callHubAsync={innerProps.callHubAsync}
                    subscribeToEvent={innerProps.subscribeToEvent}
                    connectionId={innerProps.connectionId}
                    elements={
                        elementsArray   
                    } />
            default:
                return <p>{t('session.noActivity')}</p>;
        }
    }

    return (<>
        <h2 ref={titleRef}>{props.projectName}</h2>
        <Activity />
    </>);
    
}