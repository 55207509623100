import React from 'react';
import { getCSRFToken } from '../../../utils/CSRFHelper';
import { fetchWithProgress } from '../../../utils/Fetch';
import { UploadFileActivityUI } from './UploadFileActivityUI';


export function UploadFileActivity(props) {

    function sendFileAsync(file, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file);

        return fetchWithProgress('api/files', {
            method: 'POST',
            body: formData,
            headers: {
                SessionId: props.sessionId,
                UserId: props.userId,
                RequestVerificationToken: getCSRFToken()
            },
            onProgress: e => {
                onUploadProgress(Math.round(100 * e.loaded / e.total));
            }
        });
    }

    return <UploadFileActivityUI
        activityArgs={props.activityArgs}
        sendFileAsync={sendFileAsync}
    />
}