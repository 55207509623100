// returns a cancellable promise with progress feedback
export function fetchWithProgress(url, options = {}) {
    const xhr = new XMLHttpRequest();
    let ended = false;

    const promise = new Promise((res, rej) => {
        const end = () => {
            ended = true;
            xhr.removeEventListener('load', onLoad);
            xhr.removeEventListener('abort', abortReject);
            xhr.removeEventListener('error', errorReject);
        }

        const reject = (error, context) => {
            console.log(context);
            console.log(error);
            end();
            rej(error);
        }

        const abortReject = error => reject(error, 'on-abort');
        const errorReject = error => reject(error, 'on-error');
        const onLoad = event => {
            end();
            if (event.target.status >= 200 && event.target.status <= 204) {
                res(event);
            } else {
                console.log("status: " + event.target.status);
                console.log("message: " + event.target.response);
                rej(event);
            }
        }

        xhr.open(options.method, url, true);

        for (var k in options.headers || {})
            xhr.setRequestHeader(k, options.headers[k]);

        xhr.addEventListener('load', onLoad);
        xhr.addEventListener('abort', abortReject);
        xhr.addEventListener('error', errorReject);

        if (xhr.upload && options.onProgress)
            xhr.upload.onprogress = options.onProgress;

        xhr.send(options.body);
    });

    return {
        promise,
        cancel: () => {
            if (!ended)
                xhr.abort();
        }
    };
}
